<template>
  <div class="col-lg-4 mb-lg-0 align-self-end">
    <b-button
      v-if="checkPermission('create slider')"
      v-b-modal.form-bannerslider-modal
      class="btn btn-info btn-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Add Slider"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Banner/Slider
    </b-button>

    <b-modal
      id="form-bannerslider-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Banner/Slider"
    >
      <div class="form">
        <h5>Banner/Slider</h5>
        <div class="row">
          <div class="col-lg-6">
            <label>Kategori</label>
            <validation-provider>
              <b-form-select
                v-model="formBannerSlider.category"
                :options="optionCategory"
              />
              <small
                v-for="(validation, index) in validationsSlider.category"
                v-if="validationsSlider.category"
                :key="`errorType${index}`"
                class="text-danger"
              >{{ validation }}</small>
            </validation-provider>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Judul</label>
              <validation-provider>
                <b-form-input
                  v-model="formBannerSlider.title"
                  :state="validationsSlider.title ? false:null"
                  placeholder="Nama"
                />
                <small
                  v-for="(validation, index) in validationsSlider.title"
                  v-if="validationsSlider.title"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Gambar</label><br>
              <validation-provider>
                <b-form-file
                  placeholder="Pilih file atau letakkan di sini..."
                  drop-placeholder="Drop file here..."
                  :state="validationsSlider.content_file ? false:null"
                  @change="changePhoto($event)"
                />
                <small
                  v-for="(validation, index) in validationsSlider.content_file"
                  v-if="validationsSlider.content_file"
                  :key="`errorIcon${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-12 mb-1">
            <quill-editor
              v-model="formBannerSlider.content_text"
            />
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingCategory">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingCategory"
          @click="editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormSelect,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    cleanUpForm: {
      type: Function,
    },
    parent: {
      type: Object,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      parents: [],
      isLoadingCategory: false,
      formBannerSlider: {
        title: '',
        category: '',
        content_text: '',
      },
      validationsSlider: '',
      optionCategory: [
        {
          value: '',
          text: 'Pilih Kategori',
        },
        {
          value: 'primary',
          text: 'Primary',
        },
        {
          value: 'secondary',
          text: 'Secondary',
        },
      ],
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingCategory = value
    },
    validations(value) {
      this.validationsSlider = value
    },
    formPayload: {
      handler(value) {
        this.formBannerSlider = value
        if (this.editId == null) {
          this.formBannerSlider.content_file = value.content_file
        }
      },
      deep: true,
    },
    formBannerSlider: {
      handler(value) {
        this.$emit('payload', value)
      },
      deep: true,
    },
  },
  methods: {
    changePhoto(event) {
      this.formBannerSlider.content_file = event.target.files[0] ? event.target.files[0] : event
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
